.w-100{
    width: 100%;
}

.w-90{
    width: 90%;
    
}
.w-80{
    width: 80%;
}
.w-70{
    width: 70%;
}
.w-60{
    width: 60%;
}
.w-50{
    width: 50%;
}
.w-40{
    width: 40%;
}
.w-30{
    width: 30%;
}
.w-20{
    width: 20%;
}
.w-10{
    width: 10%;
}
.w-auto {
    width: auto;
}

