.bg-primary {
    background-color: #ff7c08 !important;
}
.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-gray {
    background-color: #8f8e8e !important;
}
