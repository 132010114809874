.primary {
    color: #ff7c08 !important;
}

.white {
    color: white;
}

.blue {
    color: blue;
}

.red {
    color: red!important;
}

.yellow {
    color: #FCA119;
    ;
}

.green {
    color: rgb(9, 216, 9);
}



.blue-text {
    color: #2783DC;
}
