.title-type-1 {
    font-size: 24px;
    color: #e5832d;
    font-family: "Inter Bold", sans-serif;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    font-weight: 700;
    padding: 8px 0;
    margin-bottom: 8px;
}

.title-type-1::after {
    content: "";
    width: 120px;
    height: 4px;
    background-image: -webkit-linear-gradient(0deg,
            rgb(116, 151, 218) 0%,
            rgb(177, 110, 10) 100%);
    position: absolute;
    left: 40px;
    bottom: -2px;
    margin: 0 0 0 -40px;
}