.ant-modal-header .ant-modal-title {
  color: #154398;
  font-weight: 600;
  font-size: 24px;
}
.ant-modal-header {
  position: relative;
  padding: 16px 0px;
  margin: 0px 24px;
}
.ant-modal-header::after {
  content: "";
  background-image: -ms-linear-gradient(0deg, #154398 0, #ee1d23 100%);
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0px;
  width: 80px;
  transition: 0.3s;
  background-image: linear-gradient(90deg, #154398 0%, #e4353a 100%);
}
.ant-modal-footer {
  position: relative;
  padding: 16px 0px;
  margin: 0px 24px;
}

.ant-modal {
  margin-top: 80px;
}

.ant-modal-close {
  margin-top: -25px;
  margin-right: -25px;
}

[class*=" ant-modal"] {
  font-size: 22px;
  font-weight: 700;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: cennter;
  border-radius: 50%;
  color: black;
  background-color: #e3e3e3;
}

[class*=" ant-modal"]:hover {
  font-size: 22px;
  font-weight: 700;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: cennter;
  border-radius: 50%;
  color: white;
  background-color: #ff4d4f;
}
